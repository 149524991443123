// 用户信息的表
import request from '@/network/request.js'
/**
 * 用户的管理列表信息
 */
export function userlists (data){
	return request({
	  url: '/cadmin/api.user/lists',
	  method: 'post',
	  data,
	})
}


/**
 * 用户的状态修改
 */

export function srole_status (data){
	return request({
	  url: '/cadmin/api.user/user_status',
	  method: 'post',
	  data,
	})
}

/**
 * 删除用户信息
**/
export function del_user(data){
	return request({
	  url: '/cadmin/api.user/del_user',
	  method: 'post',
	  data,
	})
}
/**
 * 用户管理查看剧本的
**/
export function user_get_script(data){
	return request({
	  url: '/cadmin/api.user/user_get_script',
	  method: 'post',
	  data,
	})
}



